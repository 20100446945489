@import "../../../styles/variables";

.remove {
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.notification {
  margin-bottom: .25rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    .remove {
      visibility: visible;
      opacity: 1;
    }
  }
}

textarea {
  min-height: 200px;
}

