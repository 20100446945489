@import "src/styles/variables";

.dropzone {
  &:focus {
    outline: 0;
  }

  border: 2px dashed transparent;

  &.active {
    border: 2px dashed $secondary;
  }
}

.logo {
  width: 50px;
  height: 50px;
}
