@import '../../../styles/variables';
@import '../../../styles/mixins';

.container {
  border: none;
  border-radius: $border-radius;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  width: 100%;
  height: 50px;
  margin-bottom: .5rem;

  transition: box-shadow $transition-duration $transition-effect;

  &:hover {
    box-shadow: $box-shadow;
  }
}

.iconContainer {
  background: $light;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  $size: 30px;
  width: $size;
  height: $size;
  font-size: $size;
}

.icon {
  display: flex;
  height: 70%;
  width: auto;
  max-width: 80%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.label {
  // hidden for until we find a better style
  display: none;
}
