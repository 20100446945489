.modal {
  max-width: 1000px;
  width: 80%;
  max-height: 80%;
}

.video {
  width: 100%;
  height: auto;
  object-fit: fill;
}
