@import "../../styles/variables";

$border-size: 2px;
$height: 36px;
$horizontal-padding: 18px;

$border-color: transparentize($dark, 0.75);
$active-border-color: $secondary;
$text-color: $dark;

.pills {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.pill {
  .link {
    position: relative;
    color: $text-color;
    font-weight: 600;
    font-size: .9rem;
    line-height: 100%;
    padding: .3rem .5rem;
    margin: 0 .25rem;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;

    transition: border-color $transition-duration $transition-effect;

    &:hover {
      border-bottom-color: $text-color;
    }

    &.active {
      border-bottom-color: $active-border-color;
    }

    &.disabled {
      color: $text-muted;
    }
  }
}
