@import '../../../styles/variables';
@import '../../../styles/mixins';

.loader {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.search {
  width: 80%;
  height: 80vh !important;
}
