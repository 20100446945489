@import "../../styles/variables";

.linkHeader {
  text-transform: uppercase;
  color: $dark;
  display: block;
  font-size: .8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.site {
  color: inherit;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  padding: 0 .5rem;
  font-weight: 600;

  transition: all $transition-duration $transition-effect;

  &:hover {
    background: $gray-200;
    text-decoration: none;
    color: inherit;
  }
}

.siteIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
}

.active {
  background: $gray-200;
}
