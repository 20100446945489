@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: transparentize($dark, 0.15);
  padding: 3rem 0;
  background-size: cover;
}

.title {
  font-size: 1.2rem;
  margin-top: 0.75rem;
}

.subtitle {
}

.action {
  margin-top: 1.5rem;
}

.icon {
  font-size: 3rem;
}
