@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.title {
  font-size: 3rem;
  text-align: center;
  font-family: 'Source Serif Pro', serif;
  margin-bottom: 0;
}

.grid {
  width: 300px;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}
